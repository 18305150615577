import { FinanceService } from 'src/app/services/finance.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-monthly-invoice',
  templateUrl: './monthly-invoice.component.html',
  styleUrls: ['./monthly-invoice.component.scss']
})
export class MonthlyInvoiceComponent implements OnInit {
  data!:any[];
  constructor(private financeService:FinanceService) { }
  monthdate!:Date;
  totalBill!:number;
  totalPaid!:number;
  totalDiff!:number;
  cols = [
    { field: 'name', header: 'Name' },
    { field: 'rate', header: 'Rate' },
    { field: 'pid', header: 'Project' },
    { field: 'hrs', header: 'Hours' },
    { field: 'bill', header: 'Bill' },
    { field: 'paid', header: 'Paid' },
    { field:  'diff' ,header:'Difference'}
];
  ngOnInit(): void {
  }
  onSubmit(event:any){
    this.financeService.getFinanceByMonths(this.monthdate.getMonth()+1, this.monthdate.getFullYear()).subscribe((result:any)=>{
      console.log(result);
      this.data = result;
      this.calculateTotals();
    })
  }
  calculateTotals(){
    this.totalBill = 0;
    this.totalPaid = 0 ;
    this.totalDiff = 0 ;
    this.data.forEach((element:any)=>{
      this.totalBill = this.totalBill + element?.bill;
      this.totalPaid = this.totalPaid + element?.paid;
      this.totalDiff = this.totalDiff + element?.diff
    })
  }

}

<div class="wrapper">
	<app-sidebar></app-sidebar>
	<div class="main">
		<app-navbar></app-navbar>
		<main class="content">
			<router-outlet></router-outlet>
		</main>
		<app-footer></app-footer>
	</div>
</div>
<script src="assets/js/app.js"></script>

<p-toast></p-toast>
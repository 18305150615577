<nav id="sidebar" class="sidebar js-sidebar" [ngClass]="{ 'active': navbarOpen === 'true'}">
  <div class="sidebar-content js-simplebar">
    <a class="sidebar-brand">
      <!-- <span class="">Consultant Billing</span> -->
      <h1 class="h3 mb-3 align-middle text-white"><strong>Consultant</strong> Billing™</h1>
    </a>


    <ul class="sidebar-nav">


      <li class="sidebar-header">
        Insights
      </li>

      <li class="sidebar-item ">
        <a class="sidebar-link" [routerLink]="['/home']" routerLinkActive="router-link-active">
          <i class="align-middle" data-feather="sliders"></i> <span class="align-middle">Dashboard</span>
        </a>
      </li>

      <!-- <li class="sidebar-item ">
          <a class="sidebar-link" [routerLink]="['/home']" routerLinkActive="router-link-active">
<i class="align-middle" data-feather="sliders"></i> <span class="align-middle">Revenue</span>
</a>
      </li> -->

      <li class="sidebar-header">
        Masters
      </li>



      <li class="sidebar-item ">
        <a class="sidebar-link" [routerLink]="['/home/consultants']" routerLinkActive="router-link-active">
          <i class="align-middle" data-feather="sliders"></i> <span class="align-middle">Consultants</span>
        </a>
      </li>

      <li class="sidebar-item ">
        <a class="sidebar-link" [routerLink]="['/home/projects']" routerLinkActive="router-link-active">
          <i class="align-middle" data-feather="sliders"></i> <span class="align-middle">Projects</span>
        </a>
      </li>

      <!-- <li class="sidebar-item">
                <a class="sidebar-link">
      <i class="align-middle" data-feather="user"></i> <span class="align-middle">Profile</span>
    </a>
            </li> -->

      <!-- <li class="sidebar-item">
                <a class="sidebar-link" [routerLink]="['/signIn']" routerLinkActive="router-link-active">
      <i class="align-middle" data-feather="log-in"></i> <span class="align-middle">Sign In</span>
    </a>
            </li>

            <li class="sidebar-item">
                <a class="sidebar-link">
      <i class="align-middle" data-feather="user-plus"></i> <span class="align-middle">Sign Up</span>
    </a>
            </li> -->








      <!-- <li class="sidebar-header">
            Finance
        </li> -->

      <!-- <li class="sidebar-item">
          <a class="sidebar-link" [routerLink]="['/home/billing']" routerLinkActive="router-link-active active">
<i class="align-middle" data-feather="book" ></i> <span class="align-middle">Billing</span>
</a>
      </li> -->

      <li class="sidebar-item">
        <a class="sidebar-link" [routerLink]="['/home/payments']" routerLinkActive="router-link-active active">
          <i class="align-middle" data-feather="book"></i> <span class="align-middle">Finance</span>
        </a>
      </li>
      <li class="sidebar-header">
            Invoice
      </li>
      <li class="sidebar-item">
        <a class="sidebar-link" [routerLink]="['/home/invoice/invoice']" routerLinkActive="router-link-active active">
          <i class="align-middle" data-feather="book"></i> <span class="align-middle">Invoice</span>
        </a>
      </li>
      <li class="sidebar-item">
        <a class="sidebar-link" [routerLink]="['/home/invoice/monthlyinvoice']" routerLinkActive="router-link-active active">
          <i class="align-middle" data-feather="book"></i> <span class="align-middle">Month Invoices</span>
        </a>
      </li>
      <li class="sidebar-item">
        <a class="sidebar-link" [routerLink]="['/home/invoice/receivable']" routerLinkActive="router-link-active active">
          <i class="align-middle" data-feather="book"></i> <span class="align-middle">Amount Receivable</span>
        </a>
      </li>
      <li class="sidebar-header">
        Reports
      </li>

      <!-- <li class="sidebar-item">
          <a class="sidebar-link" [routerLink]="['/home/reports/utilization']" routerLinkActive="router-link-active active">
<i class="align-middle" data-feather="book" ></i> <span class="align-middle">Utilisation</span>
</a>
      </li> -->

      <li class="sidebar-item">
        <a class="sidebar-link" [routerLink]="['/home/reports/projections']"
          routerLinkActive="router-link-active active">
          <i class="align-middle" data-feather="book"></i> <span class="align-middle">Projections</span>
        </a>
      </li>

      <li class="sidebar-item">
        <a class="sidebar-link" [routerLink]="['/home/reports/utilization']"
          routerLinkActive="router-link-active active">
          <i class="align-middle" data-feather="book"></i> <span class="align-middle">Utilisation</span>
        </a>
      </li>


    </ul>
  </div>
</nav>

<script type="text/javascript">
  $(document).ready(function () {
    $('#sidebarCollapse').on('click', function () {
      $('#sidebar').toggleClass('active');
      $(this).toggleClass('active');
    });
  });

</script>

<div class="container page-config">
<div class="row text-center">
  <div class="col-12 text-white bg-white">
    <button (click)="printPDF()" class="btn btn btn-link">Download PDF</button>
  </div>
</div>
<div class="card" #invoicecard id="invoice">
  <!-- Header -->
  <div class="card-header">
    <div class="row">
      <div class="col-5">
        <img src="/assets/img/icons/pdflogo.png" class="logo-size">
      </div>
      <div class="col-1"></div>
      <div class="col-6">
        <div class="row">
          <div class="col-12 align-self-start">
            Mind Tech Inc <br>
            10998 S. Wilcrest Dr., Suite-272<br>
            Houston, TX  77099 <br>
            Ph : 646-790-9628<br>
            Email : <a href="mailto:accounts@mindtechinc.com">accounts@mindtechinc.com</a><br>
            Site: <a href="http://www.mindtechinc.com/">www.mindtechinc.com</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- header end -->
  <!-- body start -->
  <div class="row text-center">
    <div class="col-12 text-white bg-dark">
        Invoice
    </div>
  </div>
  <div class="row text-justify">
    <div class="col-6" *ngIf="consultantdata">
      Bill To :
      {{ consultantdata!.project}} , {{consultantdata!.account}}
    </div>
    <div class="col-6 text-right">
      Date: {{ current_date| date}}
    </div>
  </div>
  <div class="row pt-4">
    <div class="col-6 " *ngIf="consultantdata">
      <b>Resource Name :</b> {{ consultantdata.name }} &nbsp; ({{ consultantdata.id }})
    </div>
    <div class="col-6 text-right">
      <b>Invoice ID: &nbsp;</b> {{ current_date.getTime()}}
    </div>
  </div>
  <div class="row  pt-3" *ngIf="consultantdata">
    <div class="col-12 pt-4">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Task</th>

            <th scope="col">Week Range</th>
            <th scope="col">Rate/Hr</th>
            <th scope="col">Billable Hours</th>
            <th scope="col">Total(USD)</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of invoiceData">
            <td scope="row"> {{consultantdata.project}}</td>
            <td scope="row"> {{item.first_day_of_week |date:'dd' }} - {{item.last_day_of_week |date:'dd-MMM-yy' }}</td>
            <td scope="row"> {{consultantdata.rate}}</td>
            <td scope="row"> {{item['sum(hrs)']}}</td>
            <td scope="row"> {{item['sum(bill)']}}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td><b>Total </b></td>
            <td></td>
            <td></td>
            <td>{{ sum_hr_total }}</td>
            <td><b>{{  sum_payment_total}}</b></td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
  <div class="row" >
    <div class="col-12 pt-4">
      <table class="table table-bordered table-sm">
        <tbody>
        <tr>
          <td>REMIT PAYMENT TO</td>
          <td> Mind Tech Inc</td>
        </tr>
        <tr>
          <td> PAYMENT ADDRESS</td>
          <td> 10998, S. Wilcrest Dr., Suite-272, Houston, TX-77099</td>
        </tr>
        <tr>
          <td>EIN #</td>
          <td> 47-1995113</td>
        </tr>
        <tr>
          <td>Phone number</td>
          <td>646-790-9628</td>
        </tr>
        <tr>
          <td>Email</td>
          <td>accounts@mindtechinc.com</td>
        </tr>
      </tbody>
      </table>
    </div>
  </div>
  <div class="row text-center pt-3">
    <div class="col-12">
      Please make ACH the payment of $ {{ sum_payment_total}} to Mind Tech Inc at 10998 S. Wilcrest Dr., Suite-272 Houston TX–77099.
      <p><small>This is computer generated invoice signature not required.</small></p>
    </div>
  </div>
</div>
</div>

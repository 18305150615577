import { DashboardComponent } from './../dashboard/dashboard.component';
import { AddConsultantMasterComponent } from './add/add-consultant-master.component';
import { ConsultantMasterDashboardComponent } from './list/consultant-master-dashboard.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {path:'',component:DashboardComponent},
  { path: 'consultants', component: ConsultantMasterDashboardComponent},
  {path:'consultant',component:AddConsultantMasterComponent},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConsultantMasterRoutingModule { }

import { consultant } from './../consultant/add/consultant.model';
import { ConsultantService } from 'src/app/services/consultant.service';
import { Component, OnInit } from '@angular/core';
import {
  CalendarOptions,
  EventChangeArg,
  EventClickArg,
} from '@fullcalendar/angular';
import { FinanceService } from '../services/finance.service';
import { finance } from './finance.model';
import { ColDef } from 'ag-grid-community';
import { BtnfinanceCellRenderer } from './btn-cell-renderer.component';
import { ProjectService } from '../services/project.service';

import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
  providers: [MessageService],
})
export class PaymentsComponent implements OnInit {
  isdateclicked: boolean = false;
  financeModel: finance = new finance();
  finances: finance[] | undefined;
  isdateeventclicked: boolean = false;
  filteredConsultant: consultant | undefined;
  projects: any;
  filteredProject: any;
  columnDefs: ColDef[] = [
    {
      field: 'Edit',
      cellRenderer: 'BtnfinanceCellRenderer',
      cellRendererParams: {
        clicked: (data: any) => {
          this.onEditFinance(data);
        },
      },
      filter: 'false',
      width: 80,
      floatingFilter: false,
      pinned: 'left',
    },
    {
      field: 'name',
      headerName: 'Consultant',
      valueGetter: function (params) {
        return params.data.name + ' (' + params.data.uid + ')';
      },
    },
    { field: 'pid', headerName: 'Project' },
    {
      field: '_d',
      resizable: true,
      headerName: 'Date',
      cellRenderer: function (params: any) {
        return params.value;
      },
    },
    { field: 'rate', headerName: 'Rate / Hour' },
    { field: 'hrs', headerName: 'Hours' },
    { field: 'bill', headerName: 'Bill (USD)' },
    {
      field: 'payment',
      headerName: 'Payment (USD)',
      valueGetter: function (params) {
        return params.data.payment ? params.data.payment : 0;
      },
    },
    // { field: 'paid',
    //     cellRenderer: function(params: any) {
    //       if(!params.value) {
    //         return '<span class="text-danger"> <i class="bi bi-currency-dollar"></i> </span>'
    //       } else {
    //         return '<span class="text-success"> <i class="bi bi-check2-all"></i> </span>'
    //       }
    //     }
    // },
    {
      field: 'verified',
      resizable: true,
      suppressSizeToFit: true,
      cellRenderer: function (params: any) {
        if (!params.value) {
          return '<span class="text-danger"> <i class="bi bi-flag-fill"></i> </span>';
        } else {
          return '<span class="text-success"> <i class="bi bi-flag-fill"></i> </span>';
        }
      },
    },
  ];
  dialogHeader!: string;
  isfilterOn: boolean = false;
  isAutoImportClick: boolean = false;
  eventsList: any = [];
  calendarOptions: CalendarOptions = {
    themeSystem:'bootstrap5',
    // headerToolbar: { center: 'dayGridMonth,dayGridWeek' },
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,dayGridWeek'
    },
    initialView: 'dayGridWeek',
    dateClick: this.handleDateClick.bind(this), // bind is important!
    height: 480,
    eventColor: 'green',
    displayEventTime: false,
    weekends: false,
    validRange: {
      end: new Date(),
    },
    dayMaxEvents: true,
    events: this.eventsList,
    eventClick: (arg: EventClickArg) => {

      let reqDate = arg.event.start?.getMonth();

      this.getfinances(
        arg.event.start?.getFullYear() +
          '-' +
          ((reqDate as number) + 1) +
          '-' +
          arg.event.start?.getDate()
      );
    },

  };

  consultants: any[] = [];
  selectedConsultant: any;
  isadjustments!: boolean;

  constructor(
    private financeService: FinanceService,
    private consultantService: ConsultantService,
    private messageService: MessageService,
    private projectService: ProjectService
  ) {}

  onEditFinance(data: any) {
    this.isdateclicked = true;
    this.financeModel = data;
    this.selectedConsultant = this.consultants.find(
      (e) => e.id == this.financeModel.uid
    );
  }
  frameworkComponents = {
    BtnfinanceCellRenderer: BtnfinanceCellRenderer,
  };
  getfinances(request: any) {
    let query = "";
    if (this.filteredConsultant) {
      query = "_d = "+"\""+request + "\" and uid = " +this.filteredConsultant.id

    } else if (this.filteredProject){
      query = "_d = "+"\""+request + "\" and pid = " + this.filteredProject.project
    }
    else{
      query = "_d = "+"\""+request + "\"";
    }
    this.financeService.getFinances(query).subscribe((result: any) => {
      this.isdateeventclicked = true;
      this.dialogHeader = 'Payment Details';
      this.finances = result;
    });
  }
  getDateformat(date: Date) {
    return (
      date.getUTCFullYear() + '-' + (date.getUTCMonth() + 1) + '-' + date.getUTCDate()
    );
  }


  ngOnInit(): void {
    this.getProjects();
    this.consultantService.getConsultants().subscribe((result: any) => {
      this.consultants = result;
    });

    this.getFinances();
  }
  getFinances() {
    this.financeService.getFinance(null).subscribe((result: any) => {
      result.forEach((element: any) => {
        // element.__d = new Date(element._d);

        this.eventsList.push({
          title: element['sum(hrs)'] + ' Hrs (' + element['count(_d)'] + ')',
          date:this.setDate(element._d) ,
          color:
            element['sum(bill)'] > element['sum(payment)'] ? 'red' : 'green',
          textEscape: false,
        });
        
      });
      this.calendarOptions.events = this.eventsList;
    });
  }
  // events: any[] | undefined;
  // options = {
  //               initialDate : new Date(),
  //               headerToolbar: {
  //                   left: 'prev,next today',
  //                   center: 'title',
  //                   right: 'dayGridMonth,timeGridWeek,timeGridDay'
  //               },
  //               editable: true,
  //               selectable:true,
  //               selectMirror: true,
  //               dayMaxEvents: true,
  //               dayRender: function (date:any, cell:any) {
  //                 cell.append('<input type="number" style="margin-top: 60px; width: 30%; margin-left: 2px; z-index: 10; pointer-events: all!important">');
  //               }
  //       };
  handleDateClick(arg: any) {
    this.financeModel = new finance();
    this.financeModel._d = arg.dateStr;
    this.isdateclicked = true;
    this.selectedConsultant = new consultant();
    this.dialogHeader = 'Billing Details';
  }

  onSubmit(type: any) {
    this.financeModel.uid = this.selectedConsultant.id;
    this.financeModel.name = this.selectedConsultant.name;
    this.financeModel.pid = this.selectedConsultant.project;

    this.financeModel.bill =
      this.financeModel.hrs * this.selectedConsultant.rate;

    this.financeModel._d = this.getDateformat(new Date(this.financeModel._d));

    if (type == 'verify') {
      this.financeModel.verified = 1;
    }

    if (this.isdateeventclicked) {
      this.financeService
        .editFinance(this.financeModel)
        .subscribe((result: any) => {
          this.financeModel = new finance();
          this.isdateclicked = !this.isdateclicked;
          this.selectedConsultant = new consultant();
          this.messageService.add({
            severity: 'success',
            summary: 'Service Message',
            detail: 'Data Updated',
          });
          this.onClear();
        });
    } else {
      this.financeService
        .addFinance(this.financeModel)
        .subscribe((result: any) => {
          this.financeModel = new finance();
          this.selectedConsultant = new consultant();
          this.isdateclicked = !this.isdateclicked;
          this.messageService.add({
            severity: 'success',
            summary: 'Service Message',
            detail: 'Data Added',
          });
          this.onClear();
        });
    }
  }
  getProjects() {
    this.projectService.getProjects().subscribe((result: any) => {
      this.projects = result;
    });
  }
  onSearch() {
    this.isdateeventclicked = false;
    this.eventsList = [];
    let condition;
    if (this.filteredConsultant && !this.filteredProject) {
      condition = ' where uid=' + this.filteredConsultant.id;
    }
    if (!this.filteredConsultant && this.filteredProject) {
      condition = " where pid = '" + this.filteredProject.project + "'";
    }
    // if (this.filteredConsultant && this.filteredProject) {
    //   condition =
    //     ' where uid=' +
    //     this.filteredConsultant.id +
    //     " AND pid = '" +
    //     this.filteredProject.project +
    //     "'";
    // }
    this.financeService.getFinance(condition).subscribe((result: any) => {

      result.forEach((element: any) => {
        // element.__d = new Date(element._d);

        this.eventsList.push({
          title: element['sum(hrs)'] + ' Hrs (' + element['count(_d)'] + ')',
          date: this.setDate(element._d),
          color:
            element['sum(bill)'] > element['sum(payment)'] ? 'red' : 'green',
          textEscape: false,
        });
      });
      console.log('this.event', this.eventsList);
      this.calendarOptions.events = this.eventsList;
    });
  }
  setDate(curDate:any){
    let utcDate= new Date(curDate);
    return new Date(utcDate.getUTCFullYear(),utcDate.getUTCMonth(),utcDate.getUTCDate())
  }
  onClear() {
    this.eventsList = [];
    this.selectedConsultant = new consultant();
    this.filteredConsultant = undefined;
    this.filteredProject = undefined;
    this.isdateeventclicked = false;
    console.log('ss',this.filteredConsultant,this.filteredProject)
    this.getFinances();
  }
  onAutoImport() {
    this.isAutoImportClick = true;
  }
  isWeekend() {
    this.calendarOptions.weekends = !this.calendarOptions.weekends;
  }
  onAdjustmentClick(event:any){
    console.log('evet',event)
    this.isadjustments = true;
  }
}

import { DropdownModule } from 'primeng/dropdown';
import { ConsultantinvoiceComponent } from './consultantinvoice/consultantinvoice.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { CalendarModule } from 'primeng/calendar';
import { InvoiceRoutingModule } from './invoice-routing.moule';
import { ToastModule } from 'primeng/toast';
import { MonthlyInvoiceComponent } from './monthly-invoice/monthly-invoice.component';
import { TableModule } from 'primeng/table';
import { AccountReceivableComponent } from './account-receivable/account-receivable.component';




@NgModule({
  declarations: [
    InvoiceComponent,
    ConsultantinvoiceComponent,
    MonthlyInvoiceComponent,
    AccountReceivableComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    AgGridModule.withComponents([]),
    CalendarModule,
    FormsModule,
    InvoiceRoutingModule,
    DropdownModule,
    ToastModule,
    TableModule
  ]
})
export class InvoiceModule { }

import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConsultantService } from 'src/app/services/consultant.service';
import { consultant } from './consultant.model';
import { ThirdPartyDraggable } from '@fullcalendar/interaction';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-add-consultant-master',
  templateUrl: './add-consultant-master.component.html',
  styleUrls: ['./add-consultant-master.component.scss']
})
export class AddConsultantMasterComponent implements OnInit {

  header: string = 'Add';
  consultantId: any;
  consultantData: any;
  consultantModel: consultant = new consultant();

  constructor(
    private route: ActivatedRoute,
    private router:Router,
    private consultantService: ConsultantService
  ) {}

  ngOnInit(): void {

    this.route.queryParams.subscribe((params) => {
      console.log(params); // { orderby: "price" }
      this.consultantId = params.id;
      console.log(this.consultantId); // price
      if (this.consultantId > 0) this.getConsultantById();
    });
  }

  getConsultantById() {
    this.consultantService
      .getConsultantById(this.consultantId)
      .subscribe((arg: any) => {
        this.consultantData = arg[0];
        console.log(this.consultantData);
        this.consultantModel = this.consultantData;

        if(this.consultantModel.start_dt != null) {
          this.consultantModel.start_dt = new Date(this.consultantModel.start_dt);
        }


        if(this.consultantModel.end_dt != null) {
          this.consultantModel.end_dt = new Date(this.consultantModel.end_dt);
        }


        if (this.consultantModel.dob !== null) {
          this.consultantModel.dob = new Date(this.consultantModel.dob);
        }

      });
  }

  onTerminated() {
    this.consultantModel.active = 0;
    this.consultantModel.status = "Terminated";
    this.consultantModel.account = "";
    this.consultantModel.project = "";
    this.dateHandler();

    this.consultantService.editconsultant(this.consultantModel).subscribe((result) => {
      this.router.navigate(['/home/consultants']);
    });
  }

  onResumed() {
    this.consultantModel.active = 1;
    this.consultantModel.status = "Available";
    this.dateHandler();

    this.consultantService.editconsultant(this.consultantModel).subscribe((result) => {
      this.router.navigate(['/home/consultants']);
    });
  }

  dateHandler() {
    if(this.consultantModel.start_dt) {
      this.consultantModel.start_dt = this.getDateformat(
        new Date(this.consultantModel.start_dt)
      );
    }

    if(this.consultantModel.end_dt) {
      this.consultantModel.end_dt = this.getDateformat(
        new Date(this.consultantModel.end_dt)
      );
    }

    if(this.consultantModel.dob ) {
      this.consultantModel.dob = this.getDateformat(
        new Date(this.consultantModel.dob)
      );
    }
  }


  onSubmit() {
    this.dateHandler();

    console.log('demodata', this.consultantModel);
    if (this.consultantModel.id > 0) {
      console.log(this.consultantModel);

      this.consultantService
        .editconsultant(this.consultantModel)
        .subscribe((result) => {
          console.log('data sucess');
          this.router.navigate(['/home/consultants']);
        });
    } else {
      this.consultantService
        .addconsultant(this.consultantModel)
        .subscribe((result) => {
          console.log('success');
          this.router.navigate(['/home/consultants']);
        });
    }
  }

  datehandler() {
    if(this.consultantModel.start_dt) {
      this.consultantModel.start_dt = this.getDateformat(
        new Date(this.consultantModel.start_dt)
      );
    }

    if(this.consultantModel.end_dt) {
      this.consultantModel.end_dt = this.getDateformat(
        new Date(this.consultantModel.start_dt)
      );
    }

    if(this.consultantModel.dob ) {
      this.consultantModel.dob = this.getDateformat(
        new Date(this.consultantModel.dob)
      );
    }
  }


  onMapping(status: any) {
    this.dateHandler();
    console.log('demodata', this.consultantModel);
    this.consultantModel.status = status;

    if (this.consultantModel.id > 0) { // Edit
      console.log(this.consultantModel);
      this.consultantModel.status = status;

      if(status == "Available") {
        this.consultantModel.account = "";
        this.consultantModel.project = "";
      }

      this.consultantService.editconsultant(this.consultantModel)
        .subscribe((result) => {
          console.log('data sucess');
          this.router.navigate(['/home/consultants']);
        });
    } else {
      this.consultantService
        .addconsultant(this.consultantModel)
        .subscribe((result) => {
          console.log('success');
          this.router.navigate(['/home/consultants']);
        });
    }
  }




  getDateformat(date: Date) {
    return (
      date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    );
  }

}

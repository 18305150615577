import { Component, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { ProjectService } from 'src/app/services/project.service';


@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ProjectsListComponent implements OnInit {
  projects:any;

  columnDefs: ColDef[] = [
    { field: 'account', resizable: true, suppressSizeToFit: true },
    { field: 'project', resizable: true, suppressSizeToFit: true },
    { field: '_count', resizable: true, suppressSizeToFit: true },
];
  constructor(private projectService:ProjectService) { }

  ngOnInit(): void {
    this.getProjects()
  }
  getProjects(){
    this.projectService.getProjects().subscribe((result:any)=>{
      this.projects = result;
    })
  }

}

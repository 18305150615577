import { InvoiceService } from './../../services/invoice-sharing.service';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { finance } from 'src/app/payments/finance.model';
import { ConsultantService } from 'src/app/services/consultant.service';
import { FinanceService } from 'src/app/services/finance.service';
import { ProjectService } from 'src/app/services/project.service';
import {  Router} from "@angular/router";

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
  providers: [MessageService]
})
export class InvoiceComponent implements OnInit {
  financeModel: finance = new finance();
  consultants: any;
  selectedConsultant: any;
  startDate!: Date;
  endDate!: Date;
  financeModelList: finance[] = [];
  constructor(
    private financeService: FinanceService,
    private consultantService: ConsultantService,
    private invoice: InvoiceService,
    private messageService: MessageService,
    private router :Router
  ) {}
  ngOnInit(): void {
    this.consultantService.getConsultants().subscribe((result: any) => {
      this.consultants = result;
    });
  }

  onSubmit(event:any){
    this.financeService.getFinanceByDates(this.getDateformat(this.startDate),this.getDateformat(this.endDate),this.selectedConsultant.id).subscribe((result:any)=>{
      if(result.length > 0){
        this.invoice.setInvoiceData(result);
        const url = this.router.serializeUrl(
          this.router.createUrlTree(['/consultantinvoice'],{ queryParams: { id:  this.selectedConsultant.id} })
        );

        window.open(url, '_blank');
      }else{
        this.messageService.add({severity:'fail', summary:'Invoice', detail:'Details not present between duration'});
      }
      console.log('call',result)
    })
  }
  getDateformat(date: Date) {
    return (
      date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    );
  }
}

import { WeekDay } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ConsultantService } from 'src/app/services/consultant.service';
import { FinanceService } from 'src/app/services/finance.service';
import { ProjectService } from 'src/app/services/project.service';
import { finance } from '../finance.model';

@Component({
  selector: 'app-autoimportpayement',
  templateUrl: './autoimportpayement.component.html',
  styleUrls: ['./autoimportpayement.component.scss'],
})
export class AutoimportpayementComponent implements OnInit {
  @Output() closeModal = new EventEmitter<boolean>();
  financeModel: finance = new finance();
  consultants: any;
  selectedConsultant: any;
  startDate!: Date;
  endDate!: Date;
  financeModelList: finance[] = [];
  constructor(
    private financeService: FinanceService,
    private consultantService: ConsultantService,
    private messageService: MessageService,
    private projectService: ProjectService
  ) {}
  ngOnInit(): void {
    this.consultantService.getConsultants().subscribe((result: any) => {
      console.log('con', result);
      this.consultants = result;
    });
  }
  onSubmit(event: any) {
    console.log(this.startDate, this.endDate.toISOString(), this.financeModel);
    this.financeModelList = [];
    for (let date = this.startDate; date <= this.endDate; date.setDate(date.getDate() + 1)) {
      if(!this.isWeekend(date) ){
      let tempfinance = new finance();
      tempfinance._d = this.getDateformat(date);
      tempfinance.uid = this.selectedConsultant.id;
      tempfinance.name = this.selectedConsultant.name;
      tempfinance.pid = this.selectedConsultant.project;
      tempfinance.bill =this.financeModel.hrs * this.selectedConsultant.rate;
      tempfinance.hrs = this.financeModel.hrs;
      this.financeModelList.push(tempfinance);
      }
    }
    this.financeService.addFinancebulk(this.financeModelList).subscribe(result=>{
      this.messageService.add({severity:'success', summary:'Service Message', detail:'Data Updated'});
      this.financeModel = new finance();
      this.selectedConsultant = null;
      this.startDate = new Date(0)
      this.endDate = new Date(0);
      this.closeModal.emit(false);
    },
    error=>{
      this.messageService.add({severity:'fail', summary:'Service Message', detail:'Data failed'});
    });
  }
  getDateformat(date: Date) {
    return (
      date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    );
  }
  isWeekend(date:Date){
    if(date.getDay() === WeekDay.Saturday || date.getDay() === WeekDay.Sunday)
      return true;
    else
      return false;
  }
}

import { ConsultantMasterRoutingModule } from './consultant-master-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { ConsultantMasterDashboardComponent } from './list/consultant-master-dashboard.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { CalendarModule } from 'primeng/calendar';

import { AddConsultantMasterComponent } from './add/add-consultant-master.component';


@NgModule({
  declarations: [
    ConsultantMasterDashboardComponent,
    AddConsultantMasterComponent
  ],
  imports: [
    CommonModule,

    HttpClientModule,
    AgGridModule.withComponents([]),
    CalendarModule,
    FormsModule,
    ConsultantMasterRoutingModule
  ]
})
export class ConsultantMasterModule { }

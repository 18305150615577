import { Component, OnInit } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/angular';


@Component({
  selector: 'app-add-billing',
  templateUrl: './add-billing.component.html',
  styleUrls: ['./add-billing.component.scss']
})
export class AddBillingComponent implements OnInit {
  // firstday!: Date;
  // day!: any;
  // lastday!: Date;
  // weekdays : any;
  constructor() { }

  ngOnInit(): void {
  //   this.weekdays= []
  //  this.weekdays= this.getEntireWeekDetails();
  //   console.log('weekdays',this.weekdays);
  }

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridWeek',
    dateClick: this.handleDateClick.bind(this), // bind is important!
    events: [
      { title: 'event 1', date: '2019-04-01' },
      { title: 'event 2', date: '2019-04-02' }
    ]
  };
  events: any[] | undefined;
  options = {
                initialDate : '2019-01-01',
                headerToolbar: {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                },
                editable: true,
                selectable:true,
                selectMirror: true,
                dayMaxEvents: true
        };
  handleDateClick(arg:any) {
    alert('date click! ' + arg.dateStr)
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  public navabarOpen!:any
  public subject=  new Subject<any>();
  constructor() { }
  private messageSource = new  BehaviorSubject(this.navabarOpen);
  currentMessage = this.messageSource.asObservable();

  changeNavbarOpen(message: string) {
    this.messageSource.next(message);
    }
}

<div class="container-fluid p-0">
    <div class="mb-3">
      <!-- <h1 class="h3 d-inline align-middle">Consultant(s)</h1> -->
      <h1 class="h3 mb-3"><strong>Invoice</strong></h1>
    </div>

    <form name="form" (ngSubmit)="onSubmit('save')">
        <div class="row">

          <div class="col-lg-4 mb-3">
            <label class="form-label">Consultant</label><br>
            <p-dropdown class="form-control form-control-lg noborder"  [options]="consultants" name="selectedConsultant" [(ngModel)]="selectedConsultant" optionLabel="name"
              [filter]="true" filterBy="name" [showClear]="true" placeholder="Consultant">
              <ng-template pTemplate="selectedItem">
                <div class="country-item country-item-value" *ngIf="selectedConsultant">
                  <div>{{selectedConsultant.name}}</div>
                </div>
              </ng-template>
              <!-- <ng-template let-country pTemplate="item">
                <div class="country-item">
                  <div>{{country.name}}</div>
                </div>
              </ng-template> -->
            </p-dropdown>
          </div>

          <br/>

          <div class="col-lg-4" *ngIf="selectedConsultant">

            <label class="form-label">Consultant</label><br>
            <label>{{selectedConsultant.name}}</label>
          </div>

          <div class="col-lg-4" *ngIf="selectedConsultant">
            <label class="form-label">Project</label><br>
            <label>{{selectedConsultant.project}}</label>
          </div>

        </div>

        <br/>


        <div class="row" >

          <div class="col-4 mb-3">
            <label class="form-label">From Date</label>
            <p-calendar  inputId="simple" appendTo="body"  [style]="{'width':'100%'}"  [inputStyle]="{'width':'100%'}" [(ngModel)]="startDate" name="startDate"  ></p-calendar>

          </div>
          <div class="col-4 mb-3">
            <label class="form-label">To Date</label>
            <p-calendar  inputId="simple" appendTo="body"  [style]="{'width':'100%'}"  [inputStyle]="{'width':'100%'}" [(ngModel)]="endDate" name="endDate"  ></p-calendar>
          </div>



        </div>
        <div class="row" >

          <div class="col-10 mb-3">
            </div>
            <div class="col-1 mb-3">
          <button  class="btn btn-lg-0 btn-primary" type="button" label="Submit" (click)="onSubmit('save')" >Submit</button> &nbsp;
              </div>
      </div>

      </form>
</div>
<p-toast></p-toast>

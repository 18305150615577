export class finance{
  uid!: number;
  name!: any;
  pid!: number;
  _d!: any;
  paid!: number;

  hrs!: number;
  bill!: number;
  payment!: number;
  verified!: number;
}

import { HttpClient } from '@angular/common/http';
import { ThrowStmt } from '@angular/compiler';
import { Injectable } from '@angular/core';
// import { stat } from 'fs';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConsultantService {
   url =  environment.url;
  constructor(private http:HttpClient) { }

  getConsultantsData(pageSize: number, pageNumber: number):Observable<any>{
    // const url = "assets/consultants.json?size="+pageSize+"&page="+pageNumber;
    return this.http.get(this.url+'/api/consultant?_p='+pageNumber+'&_size='+pageSize);
  }
  getConsultants():Observable<any>{
    // const url = "assets/consultants.json?size="+pageSize+"&page="+pageNumber;
    return this.http.get(this.url+'/api/consultant?_where=(active,eq,1)');
  }
  getConsultantById(consultantId:number){
    return this.http.get(this.url+'/api/consultant/'+consultantId);
  }
  addconsultant(consultant:any){
    return this.http.post(this.url + '/api/consultant',consultant);
  }

  editconsultant(consultant:any){
    console.info(consultant);
    return this.http.put(this.url + '/api/consultant', consultant);
  }

  getConsultantswithFilter(name:string,billing_period:string,status:string, active: string):Observable<any>{
    let nameQuery="";
    let billing_periodQuery= "";
    let statusQuery = "";
    let activeQuery = "";

    if(name!==undefined){
      nameQuery += "(name,like,"+'~'+name+"~)";
    }

    if(status !==undefined && status != ""){
      statusQuery +=(status === 'All' ?"":"~and(status,eq,"+status+")");
    }

    if(active !== undefined && active != "") {
      if(nameQuery || statusQuery) { active += "~and"}
      activeQuery +=(status === 'All' ?"":"(active,eq,"+active+")");
    }

     if(billing_period !== undefined){
      billing_periodQuery +=(name !==undefined ? "~and(billing_period,eq,"+billing_period+")":"(billing_period,eq,"+billing_period+")");
    }

    console.log('n',nameQuery,'b',billing_periodQuery,'s',statusQuery, 'a', activeQuery);
    // const url = "assets/consultants.json?size="+pageSize+"&page="+pageNumber;
    return this.http.get(this.url+'/api/consultant?_where=' + nameQuery + billing_periodQuery + statusQuery + activeQuery);
  }
}

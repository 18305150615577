import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {
  displayResponsive:boolean = false;
  constructor() { }
  selectedConsultant:any
  consultants:any[]=[
    {name: 'C1', code: 'C1'},
    {name: 'C2', code: 'BR'},
    {name: 'C3', code: 'CN'},
    {name: 'C4', code: 'EG'},
    {name: 'C5', code: 'FR'},
    {name: 'C6', code: 'DE'},
    {name: 'C7', code: 'IN'},
    {name: 'C8', code: 'JP'},
    {name: 'C9', code: 'ES'},
    {name: 'C10', code: 'US'}
];
  ngOnInit(): void {
  }
  showResponsiveDialog(){
    console.log('selectedConsultant',this.selectedConsultant);
    this.displayResponsive = !this.displayResponsive;
  }
}

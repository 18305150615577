import { Observable } from 'rxjs/internal/Observable';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  private sharedData: Subject<any> = new Subject<any>();
  sharedData$: Observable<any> = this.sharedData.asObservable();

  constructor() { }

  setInvoiceData(invoicedData: any) {
    localStorage.setItem('invoice', JSON.stringify(invoicedData));
  }
  getInvoiceData(){
    var data:any =localStorage.getItem('invoice');
    return JSON.parse(data);
  }
  clearInvoiceData(){
    localStorage.removeItem('invoice')
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FinanceService {
  url =  environment.url;
  constructor(private http: HttpClient) { }


  getFinance(request: any) : Observable<any>{
    // return this.http.get(this.url+'/api/finance/groupby/?_fields=_d,pid');
    let query:any;
    if(request !== null){
      query =  {
        "query": "select _d, count(_d), sum(bill), sum(payment), sum(payment), sum(verified), sum(hrs) from finance " +request +" group by _d",
        "params": ["finance"]
    }
    }else{
    query =  {
      "query": "select _d, count(_d), sum(bill), sum(payment), sum(payment), sum(verified), sum(hrs) from finance group by _d",
      "params": ["finance"]
  }
}
    return this.http.post(this.url+'/dynamic',query);
  }

  addFinance(finance:any){
    return this.http.post(this.url + '/api/finance',finance);
  }
  getFinances(request: any) : Observable<any>{
    // return this.http.get(this.url+'/api/finance/groupby/?_fields=_d,pid');

    let query =  {
      "query": "select * from finance where "+request,
      "params": ["finance"]
  }
  return this.http.post(this.url+'/dynamic',query);
}
editFinance(finance:any){
  return this.http.put(this.url + '/api/finance',finance);
}
addFinancebulk(finance:any){
  console.log('finance',finance)
  return this.http.post(this.url + '/api/finance/bulk',finance);
}
  getFinanceByDates(fromDate:string,toDate:string,consultnatId:any){
   var  query =  {
      "query": "SELECT  MIN(_d) as first_day_of_week, MAX(_d) as last_day_of_week,WEEK(_d) _date,count(_d), sum(bill), sum(payment), sum(paid), sum(verified), sum(hrs)  from finance where uid = "+ consultnatId +" AND _d BETWEEN '" +fromDate+"' AND '"+toDate+"' group by WEEK(_d)",
      "params": ["finance"]
  }
    return this.http.post(this.url+'/dynamic',query);
  }
  getFinancesById(request: any,pid:any) : Observable<any>{
    // return this.http.get(this.url+'/api/finance/groupby/?_fields=_d,pid');

    let query =  {
      "query": "select * from finance where  uid = " + pid +" and _d = "+"\""+request + "\"",
      "params": ["finance"]
  }
  return this.http.post(this.url+'/dynamic',query);
}
getFinanceByMonths(month:number,year:number){
  var  query = {
    "query": "SELECT  sum(finance.payment) as paid,sum(bill) as bill, sum(hrs) as hrs,(sum(bill)-sum(COALESCE(finance.payment,0))) as diff, finance.uid ,pid,finance.name,consultant.rate from finance , consultant where  MONTH(_d) = "+month +" AND YEAR(_d) = "+year +" and consultant.id =finance.uid  group by uid ,name,pid",
    "params": [
        "finance"
    ]
}
   return this.http.post(this.url+'/dynamic',query);
 }
 getAllFinance(){
  var  query = {
    "query": "SELECT  finance.payment as paid,bill as bill, hrs as hrs,(COALESCE(bill,0)-COALESCE(finance.payment,0)) as diff, finance.uid ,pid,finance.name,consultant.rate from finance LEFT JOIN consultant ON consultant.id =finance.uid ",
    "params": [
        "finance"
    ]
}
   return this.http.post(this.url+'/dynamic',query);
  }
  getAllFinanceByGroup(){
    var  query = {
      "query": "SELECT  sum(finance.payment) as paid,sum(bill) as bill, sum(hrs) as hrs,( sum(bill)  - sum(COALESCE (finance.payment,0))) as diff, finance.uid ,pid,finance.name,consultant.rate from finance , consultant where   consultant.id =finance.uid  group by uid ,name,pid",
      "params": [
          "finance"
      ]
  }
  return this.http.post(this.url+'/dynamic',query);
}
}

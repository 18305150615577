import { ConsultantinvoiceComponent } from './consultantinvoice/consultantinvoice.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgxEchartsModule } from 'ngx-echarts';
import { MonthlyInvoiceComponent } from './monthly-invoice/monthly-invoice.component';
import { AccountReceivableComponent } from './account-receivable/account-receivable.component';


const routes: Routes = [
  // {path:'u1', component:UtilizationComponent},
  {path:'invoice', component:InvoiceComponent},
  {path:'monthlyinvoice', component:MonthlyInvoiceComponent},
  {path: 'receivable', component:AccountReceivableComponent}

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InvoiceRoutingModule { }

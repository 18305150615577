<div class="container-fluid p-0">
  <div class="mb-3">
    <!-- <h1 class="h3 d-inline align-middle">Consultant(s)</h1> -->
    <h1 class="h3 mb-3"><strong>Project</strong> Master</h1>
  </div>

  <div class="row">
    <div class="col-12 col-lg-8 col-xxl-12 d-flex">
      <div class="card flex-fill">
        <!-- <div class="card-header">
          <h5 class="card-title mb-0">Projects</h5>
        </div> -->
        <!-- <ag-grid-angular
        style="width: 100%; height: 70vh;"
        class="ag-theme-alpine"
        [rowData]="projects"
        [columnDefs]="columnDefs">
    </ag-grid-angular> -->

    <p-table [value]="projects">
      <ng-template pTemplate="header">
          <tr>
              <th>Account</th>
              <th>Project</th>
              <th>Active Resources</th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
          <tr>
              <td>{{product.account}}</td>
              <td>{{product.project}}</td>
              <!-- <td>{{product._count}}</td> -->
              <td><span [class]=""><p-badge value="{{product._count}}" severity="success"  severity="success"></p-badge></span></td>
          </tr>
      </ng-template>
    </p-table>


      </div>
    </div>
  </div>
</div>



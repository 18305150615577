import { Component, OnInit } from '@angular/core';
import { FinanceService } from 'src/app/services/finance.service';

@Component({
  selector: 'app-account-receivable',
  templateUrl: './account-receivable.component.html',
  styleUrls: ['./account-receivable.component.scss']
})
export class AccountReceivableComponent implements OnInit {
  totalBill!:number;
  totalPaid!:number;
  totalDiff!:number;
  data!:any[];
  isGroupBy:boolean = false;
  cols = [
    { field: 'name', header: 'Name' },
    { field: 'rate', header: 'Rate' },
    { field: 'pid', header: 'Project' },
    { field: 'hrs', header: 'Hours' },
    { field: 'bill', header: 'Bill' },
    { field: 'paid', header: 'Paid' },
    { field:  'diff' ,header:'Difference'}
];
  constructor(private financeService:FinanceService) { }

  ngOnInit(): void {
    this.getAllFinances();
  }
  getAllFinances(){
    this.financeService.getAllFinance().subscribe((result:any)=>{
      console.log(result);
      this.data = result;
      this.calculateTotals();
      this.isGroupBy = false;
    })
  }
  calculateTotals(){
    this.totalBill = 0;
    this.totalPaid = 0 ;
    this.totalDiff = 0 ;
    this.data.forEach((element:any)=>{
      this.totalBill = this.totalBill + element?.bill;
      this.totalPaid = this.totalPaid + element?.paid;
      this.totalDiff = this.totalDiff + element?.diff;
    })
  }
  GroupByfunc(){
    this.financeService.getAllFinanceByGroup().subscribe((result:any)=>{
      console.log(result);
      this.data = result;
      this.calculateTotals();
      this.isGroupBy = true;
    })
  }
}

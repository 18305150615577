<!-- <table class="table table-hover my-0">
    <thead>
      <tr>
        <th>Project Name</th>
        <th class="d-none d-xl-table-cell" *ngFor="let item of weekdays">{{item.day | date:'dd-MM'}} {{item.day | date:'EEE'}}</th>
      </tr>
    </thead>
    <tbody>
        <tr>
            <td>Time Spent</td>
            <td class="d-none d-xl-table-cell" *ngFor="let item of weekdays"> 
                <input class="form-control form-control-lg"  type="text" >
            </td>
          </tr>
      <tr>
      <tr>
        <td>Project Apollo</td>
      
        <td class="d-none d-xl-table-cell" *ngFor="let item of weekdays">
            <input class="form-check-input" type="checkbox" [(ngModel)]="item.selected" (click)="getData()" >
        </td>
      </tr>
    </tbody>
  </table> -->

  <full-calendar [deepChangeDetection]="true"  [options]="calendarOptions" ></full-calendar>


<div class="container-fluid p-0">
    <div class="mb-3">
      <!-- <h1 class="h3 d-inline align-middle">Consultant(s)</h1> -->
      <h1 class="h3 mb-3"><strong>Consultant</strong> Master</h1>
    </div>


    <div class="row">
      <div class="col-md-4 col-xl-3">
        <div class="card mb-3">
          <div class="card-header">
            <h5 class="card-title mb-0">Filter</h5>
          </div>

          <hr class="my-0" />

          <div class="m-sm-4">
            <form>
              <div class="mb-3">
                <label class="form-label">Name</label>
                <input
                  class="form-control form-control-lg"
                  type="text"
                  name="consultantname"
                  [(ngModel)]="consultantname"
                  placeholder="Consultant Name"
                />
              </div>
              <!-- <div class="mb-3">
                <label class="form-label">Start Date</label>

                <p-calendar class="form-control-lg"></p-calendar>
              </div> -->
              <!-- <div class="mb-3">
                <label class="form-label">Billing Period</label>

                <select class="form-select" [(ngModel)]="billing_period" name="billing_period">
                  <option selected value="Weekly">Weekly</option>
                  <option value="Fortnightly">Fortnightly</option>
                  <option value="Monthly">Monthly</option>
                </select>
              </div> -->
              <div class="mb-3">
                <label class="form-label">Status</label>
                <!-- (change)="onPageSizeChanged($event)" -->
                <select  class="form-select" [(ngModel)]="status" name="status">
                  <option value="" selected>All</option>
                  <option value="Available">Available</option>
                  <option value="Onboarded">Onboarded</option>
                  <option value="Terminated">Released</option>
                  <option value="Terminated">Terminated</option>
              </select>
              </div>

              <div class="mb-3">
                <label class="form-label">Active Flag</label>
                <!-- (change)="onPageSizeChanged($event)" -->
                <select  class="form-select" [(ngModel)]="active" name="active">
                  <option value="">All</option>
                  <option value="1" selected>Active</option>
                  <option value="0">In-Active</option>
              </select>
              </div>


              <div class="text-center mt-3">
                <button class="btn btn-lg-0 btn-primary" (click)="onSearch()">Search</button>&nbsp;
                 <button  class="btn btn-lg-0 btn-secondary" (click)="onClear()">Clear</button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="col-md-8 col-xl-9">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-lg-3">

              </div>

              <div class="d-flex bd-highlight">

                <div class="p-2 flex-grow-1">
                  <a [routerLink]="['/home/consultant']" class="btn btn-primary">
                    <i class="bi bi-person-plus"></i> Add Consultant</a> &nbsp;
                  <!-- <button (click)="onBtnExport()" class="btn btn-primary">
                    <i class="bi bi-people"></i> Export
                  </button> -->
                </div>
                <div class="p-2 bd-highlight"></div>
                <div class="p-2 bd-highlight">
                  <select (change)="onPageSizeChanged($any($event.target).value)" class="form-select">
                    <option value="10">10</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                </select>
                </div>

              </div>


              <!-- <div class="col-lg-4 justify-content-end">
                <button (click)="getSelectedRows()" class="btn btn-primary"> Get Selected Rows</button>
              </div> -->
              <div class="col-lg-4 text-end">

              </div>
              <div class="col-lg-3 text-end">

              </div>
            </div>
          </div>
          <div class="card-body h-100">
            <div class="row">
              <div class="col-lg-12">
                <!-- <ag-grid-angular
                  #agGrid
                  style="width: 100%; height: 500px"
                  class="ag-theme-alpine"
                  [pagination]="true"
                  [rowData]="rowData"
                  [columnDefs]="columnDefs"
                  [defaultColGroupDef]="defaultColGroupDef"
                  [defaultColDef]="defaultColDef"
                  [frameworkComponents]="frameworkComponents"
                  [suppressExcelExport]="true"
                  rowSelection="multiple"
                  (gridReady)="onGridReady($event)"
                >
                </ag-grid-angular> -->

                <ag-grid-angular
                  #agGrid
                  style="width: 100%; height: 440px"
                  class="ag-theme-alpine"
                  [rowData]="rowData"
                  id="myGrid"
                  [columnDefs]="columnDefs"
                  [pagination]="true"
                  (gridReady)="onGridReady($event)"

                  [paginationPageSize]="defaultPageSize"

                  [enableRangeSelection]="true"
                  [frameworkComponents]="frameworkComponents"
                  [suppressExcelExport]="true"
                  rowSelection="multiple"
                  [defaultColDef]="defaultColDef"
                >
                </ag-grid-angular>
                <!-- <ag-grid-angular #agGrid
      style="width: 100%; height: 100%;"
      class="ag-theme-alpine"
      [rowData]="rowData"
      id="myGrid"
      [columnDefs]="columnDefs"
      [pagination]="true"
      (gridReady)="onGridReady($event)"
      [rowModelType]="rowModelType"
      [paginationPageSize]="defaultPageSize"
      [enableRangeSelection]="true"
      [suppressExcelExport]="true"
      [defaultColDef]="defaultColDef"

      [enableRangeSelection]="true"
                  [frameworkComponents]="frameworkComponents"

                  rowSelection="multiple"

    ></ag-grid-angular> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

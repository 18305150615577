import { Observable } from 'rxjs/internal/Observable';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  url =  environment.url;
  constructor(private http:HttpClient) { }

  getProjects():Observable<any>{
    return this.http.get(this.url+'/api/consultant/groupby?_fields=project,account,_count&_having=(_count,gt,0)');
  }

}

import { HttpClient } from '@angular/common/http';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  url =  environment.url;
  constructor(private http:HttpClient) { }
  getCounter(){
     return this.http.get(this.url+'/api/consultant/distinct?_fields=project');
  }
  getTrendChart(){

  }
  getPieChart(){

  }
  getfinance(){
    return this.http.get(this.url+'/api/finance/aggregate?_fields=bill,payment');
  }
  postdynamicQUery(){
    let query =  {
      "query": "SELECT  month(_d), count(pid), sum(bill),sum(payment), max(bill), max(payment)  FROM consultant_db.finance group by month(_d);",
      "params": ["finance"]
  }
    return this.http.post(this.url+'/dynamic',query);
  }



  projectRevenue(){
    let query =  {
      "query": "SELECT  pid, sum(bill),sum(payment)  FROM consultant_db.finance group by pid;",
      "params": ["finance"]
  }
    return this.http.post(this.url+'/dynamic', query);
  }
}

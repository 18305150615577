import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConsultantService } from 'src/app/services/consultant.service';
import { InvoiceService } from 'src/app/services/invoice-sharing.service';

@Component({
  selector: 'app-consultantinvoice',
  templateUrl: './consultantinvoice.component.html',
  styleUrls: ['./consultantinvoice.component.scss']
})
export class ConsultantinvoiceComponent implements OnInit, OnDestroy {

  invoiceData!:any;
  consultantId!:any
  consultantdata!:any;
  current_date = new Date();
  sum_payment_total: number = 0;
  sum_hr_total: number = 0;
  @ViewChild('invoicecard', { static: true }) invoicecard!: ElementRef;
  constructor( private invoice: InvoiceService,private route: ActivatedRoute,private consultant:ConsultantService) { }
  ngOnDestroy(): void {
    this.invoice.clearInvoiceData();
  }

  ngOnInit(): void {
    this.invoiceData = this.invoice.getInvoiceData();
    this.get_total_hr_payment();
    console.log('this',this.invoiceData);
    this.route.queryParams
      .subscribe(params => {
        console.log(params);
        this.consultantId = params.id;
        this.getConsultantById(this.consultantId)
        console.log(this.consultantId);
      }
    );
  }
  getConsultantById(id:any){
    this.consultant.getConsultantById(id).subscribe((result:any)=>{
      this.consultantdata = result[0];
    })
  }
  get_total_hr_payment(){
    this.sum_payment_total = 0;
    this.sum_hr_total = 0;
    if(this.invoiceData){
    this.invoiceData.forEach((element:any) => {
      this.sum_payment_total += element['sum(bill)'];
      this.sum_hr_total += element['sum(hrs)'];
    });
  }
  }
   printPDF() {
    window.print();
  }
}

<div class="container-fluid p-0">
  <div class="mb-3">
    <!-- <h1 class="h3 d-inline align-middle">Billing</h1> -->
    <h1 class="h3 d-inline align-middle"><strong>Billing & Payment</strong> Details</h1>
    <!-- <a class="badge bg-dark text-white ms-2" [routerLink]="['/home']">Import</a> -->
  </div>
  <div class="row">
    <div class="col-lg-4 pb-3">
      <!-- <label class="form-label">Consultant</label><br> -->
      <p-dropdown class="form-control noborder"   [options]="consultants" name="filteredConsultant" [disabled]="filteredProject !== undefined" [(ngModel)]="filteredConsultant" optionLabel="name"
        [filter]="true" filterBy="name" [showClear]="true" placeholder="All">
        <ng-template pTemplate="selectedItem">
          <div class="country-item country-item-value" *ngIf="filteredConsultant">
            <div>{{filteredConsultant.name}}</div>
          </div>
        </ng-template>
        <ng-template let-country pTemplate="item">
          <div class="country-item">
            <div>{{country.name}}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    <div class="col-lg-3 pb-3">
      <!-- <label class="form-label">project</label><br> -->
      <p-dropdown class="form-control form-control-sm noborder"  [disabled]="filteredConsultant !== undefined" [options]="projects" name="filteredProject" [(ngModel)]="filteredProject" optionLabel="project"
        [filter]="true" filterBy="project" [showClear]="true" placeholder="Project">
        <ng-template pTemplate="selectedItem">
          <div class="country-item country-item-value" *ngIf="filteredProject">
            <div>{{filteredProject.project}}</div>
          </div>
        </ng-template>
        <ng-template let-country pTemplate="item">
          <div class="country-item">
            <div>{{country.project}}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    <div class="col-lg-3 pb-3">
      <button [disabled]="!filteredConsultant && !filteredProject" class="btn btn-lg-0 btn-primary" (click)="onSearch()">Search</button>
      &nbsp;
      <button  class="btn btn-lg-0 btn-secondary" (click)="onClear()">clear</button>
    </div>
    <div class="col-lg-2">
      <button  class="btn btn-lg-0 btn-primary" (click)="onAutoImport()">Auto Import</button> </div>
    <br/><br/>
    <div class="col-lg-12 no-gutters ">
      <full-calendar   [deepChangeDetection]="true" [options]="calendarOptions"></full-calendar>
    </div>
    <div class="col-lg-4">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" (click)="isWeekend()">
        <label class="form-check-label" for="flexCheckDefault">
           Weekend
        </label>
      </div>
    </div>
  </div>
</div>



<p-dialog [(visible)]="isdateclicked" [style]="{width: '50vw'}" [header]="dialogHeader">
  <!-- <ng-template pTemplate="header" [header]="dialogHeader">
      Header content here
  </ng-template> -->

  <form name="form" (ngSubmit)="onSubmit('save')">
    <div class="row">

      <div class="col-lg-4 mb-3" *ngIf="dialogHeader == 'Billing Details'">
        <label class="form-label">Consultant</label><br>
        <p-dropdown class="form-control form-control-lg noborder"  [options]="consultants" name="selectedConsultant" [(ngModel)]="selectedConsultant" optionLabel="name"
          [filter]="true" filterBy="name" [showClear]="true" placeholder="Consultant">
          <ng-template pTemplate="selectedItem">
            <div class="country-item country-item-value" *ngIf="selectedConsultant">
              <div>{{selectedConsultant.name}}</div>
            </div>
          </ng-template>
          <!-- <ng-template let-country pTemplate="item">
            <div class="country-item">
              <div>{{country.name}}</div>
            </div>
          </ng-template> -->
        </p-dropdown>
      </div>

      <br/>

      <div class="col-lg-4" *ngIf="selectedConsultant && dialogHeader != 'Billing Details'">

        <label class="form-label">Consultant</label><br>
        <label>{{selectedConsultant.name}}</label>
      </div>

      <div class="col-lg-4" *ngIf="selectedConsultant">
        <label class="form-label">Project</label><br>
        <label>{{selectedConsultant.project}}</label>
      </div>

    </div>

    <br/>

    <div class="row">



      <div class="col-4 mb-3" *ngIf="selectedConsultant">
        <label class="form-label">Rate (Per Hour)</label>
        <input class="form-control form-control-lg" [disabled]="selectedConsultant" type="text" name="rate" [(ngModel)]="selectedConsultant.rate" placeholder="Enter Rate">
      </div>

      <div class="col-4 mb-3">
        <label class="form-label">No Of Hours</label>
        <input class="form-control form-control-lg" type="text" name="hrs" [(ngModel)]="financeModel.hrs" placeholder="Enter hrs" />
      </div>

    </div>
    <div class="row" *ngIf="dialogHeader != 'Billing Details'">

      <div class="col-4 mb-3">
        <label class="form-label">Payment Recieved</label>
        <input class="form-control form-control-lg" type="text" name="payment" [(ngModel)]="financeModel.payment" placeholder="Enter payement" />
      </div>

      <div class="col-lg-4">
        <label class="form-label">Verification Status</label><br>
        <p-tag styleClass="mr-2" severity="success" value="Verified" *ngIf="financeModel.verified"></p-tag>
        <p-tag severity="danger" value="Not Verified" *ngIf="!financeModel.verified"></p-tag>

      </div>

    </div>
  </form>
  <ng-template pTemplate="footer">
    <div *ngIf="!financeModel.verified">
    <button  class="btn btn-lg-0 btn-primary" type="button" label="Submit" (click)="onSubmit('save')" >Submit</button> &nbsp;
    <button  class="btn btn-lg-0 btn-success" type="submit" label="Verify" (click)="onSubmit('verify')" *ngIf="dialogHeader != 'Billing Details'">Verify</button>
  </div>
  </ng-template>
</p-dialog>


<div *ngIf="isdateeventclicked">
  <ag-grid-angular
  style="width: 100%; height: 70vh;"
  class="ag-theme-alpine"
  [rowData]="finances"
  [columnDefs]="columnDefs"
  [frameworkComponents]="frameworkComponents">
</ag-grid-angular>
</div>
<p-toast></p-toast>
<p-dialog [(visible)]="isAutoImportClick" [style]="{width: '50vw'}" [header]="'Auto Import'">
<app-autoimportpayement (closeModal)="isAutoImportClick = $event"></app-autoimportpayement>
</p-dialog>

import { InvoiceModule } from './invoice/invoice.modules';
import { ProjectsListComponent } from './projects/list/list.component';


import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignInComponent } from './sign-in/sign-in.component';
import { BillingComponent } from './bill-master/billing/billing.component';
import { PaymentsComponent } from './payments/payments.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ConsultantMasterModule } from './consultant/consultant-master.module';
import { ReportsRoutingModule } from './reports/reports-routing.module';
import { ConsultantinvoiceComponent } from './invoice/consultantinvoice/consultantinvoice.component';
import { AuthGuardGuard } from './auth/auth-guard.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'signIn',
    pathMatch: 'full',
  },{
    path: 'signIn',
    component: SignInComponent,
    }, {
      path:'home',
      component:HomeComponent,
      canActivate:[AuthGuardGuard],
      children: [{
        path: 'dashboard',
        component: DashboardComponent,
        }, {
          path: 'billing',
          component: BillingComponent,
          }, {
          path: 'payments',
          component: PaymentsComponent,
          },
        {
      path: '',
      loadChildren:()=>ConsultantMasterModule
  },{
    path:'reports',
    loadChildren:()=>ReportsRoutingModule,
    canActivate:[AuthGuardGuard],
  },
  {
    path:'invoice',
    loadChildren:()=>InvoiceModule,
    canActivate:[AuthGuardGuard],
  },
{
  path:'projects',
  component:ProjectsListComponent,
  canActivate:[AuthGuardGuard],
}]
    },
    {path:'consultantinvoice', component: ConsultantinvoiceComponent,canActivate:[AuthGuardGuard],},
    {
      path: '**',
      redirectTo: 'signIn'
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

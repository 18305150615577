export class consultant{
    id!: number;
    active:any;
    bgv:any;
    dob!: any;
    email!: string;

    start_dt!: any;
    end_dt!: any;

    name!:string;
    phone:any;
    project:any;
    rate!:number;
    role:any;

    status!:string;
    account!:string;
    bgv_by!:string;
    bgv_dt!:any;
    billing_period:any;
    payment_terms: any;

}

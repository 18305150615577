<div class="container-fluid p-0">
    <div class="mb-3">
        <!-- <h1 class="h3 d-inline align-middle">Consultant(s)</h1> -->
        <h1 class="h3 mb-3"><strong>Invoice</strong></h1>
    </div>

    <form name="form" (ngSubmit)="onSubmit('save')">
        <div class="row">

            <div class="col-4 mb-3">
                <label  class="form-label">Month Picker</label><br/>
                <p-calendar [(ngModel)]="monthdate" name="monthdate" view="month" dateFormat="mm/yy" [yearNavigator]="true"
                    yearRange="2000:2100" [readonlyInput]="true" inputId="monthpicker"></p-calendar>
            </div>

       
            
            
            <div class="col-1 mb-3">
                <button  class="btn btn-lg-0 btn-primary margin-top-10" type="button" label="Submit"
                    (click)="onSubmit('save')">Submit</button> &nbsp;
            </div>
        </div>

    </form>
</div>
<div class="row">
    <div class="col-12">
        <p-table #dt [columns]="cols" [value]="data" selectionMode="multiple"  responsiveLayout="scroll">
           
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns">
                        {{col.header}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr >
                    <td *ngFor="let col of columns">
                        {{rowData[col.field]}}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer" >
                <tr>
                    <td colspan="4" class="p-text-right">Totals</td>
                    <td>{{ totalBill| currency: 'USD'}}</td>
                    <td>{{totalPaid | currency: 'USD'}}</td>
                    <td>{{ totalDiff | currency: 'USD'}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>

</div>
<p-toast></p-toast>
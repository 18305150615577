import { NavbarService } from './../navbar.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  navbarOpen:string = 'false';
  public navbarOpenService: any;
  public menuItems!: any[];

  constructor(private navService:NavbarService) { }
 
  ngOnInit(): void {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
   this.navbarOpenService =  this.navService.currentMessage.subscribe((navbaropen:string)=>{
      this.navbarOpen = navbaropen;
      
    });

  }
  
  
}
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'Dashboard',  icon: 'design_app', class: 'active' },
  { path: '/icons', title: 'Icons',  icon:'education_atom', class: '' },
  { path: '/maps', title: 'Maps',  icon:'location_map-big', class: '' },
  { path: '/notifications', title: 'Notifications',  icon:'ui-1_bell-53', class: '' },

  
  

];
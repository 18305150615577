import { NavbarService } from './../navbar.service';
import { Component, OnInit } from '@angular/core';
import { stringify } from '@angular/compiler/src/util';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor(private navService:NavbarService) { }

  ngOnInit(): void {
  }
  navbarOpen = false;
  toggleNavbar() {
    this.navbarOpen=!this.navbarOpen;
    this.navService.changeNavbarOpen(!this.navbarOpen? 'true':'false');
    
  }
}

<div class="container-fluid p-0">


  <div class="row">
    <div class="col-12 col-lg-3 col-xxl-3 d-flex">
      <div class="card flex-fill">
        <div class="card-header">

          <h5 class="card-title mb-0">Utilization</h5>
        </div>

        <div class="card-body">
          <div echarts [options]="gaugeChartoption" class="demo-chart"></div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-9 col-xxl-9 d-flex">
      <div class="card flex-fill w-100">
        <div class="card-header">

          <h5 class="card-title mb-0">Contribution</h5>
        </div>
        <div class="card-body ">
          <div echarts [options]="calendar_simple_option" class="demo-chart"></div>
        </div>
      </div>
    </div>
  </div>
</div>

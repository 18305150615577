import { UtilizationComponent } from './utilization/utilization.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgxEchartsModule } from 'ngx-echarts';
import { ProjectionsComponent } from './projections/projections.component';

const routes: Routes = [
  // {path:'u1', component:UtilizationComponent},
  {path:'utilization', component:UtilizationComponent},
  {path:'projections', component: ProjectionsComponent}
];

@NgModule({
  imports: [NgxEchartsModule.forRoot({
    echarts: () => import('echarts')
  }),
RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }

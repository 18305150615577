

import { DropdownModule } from 'primeng/dropdown';

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentModule } from './component/component.module';
import { SignInComponent } from './sign-in/sign-in.component';
import { HomeComponent } from './home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AgGridModule } from 'ag-grid-angular';
import { PaymentsComponent } from './payments/payments.component';
import { BillingComponent } from './bill-master/billing/billing.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { AddBillingComponent } from './bill-master/add-billing/add-billing.component';
import {DialogModule} from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!

import interactionPlugin from '@fullcalendar/interaction'; // a plugin!
import { CalendarModule } from 'primeng/calendar';
import {TabViewModule} from 'primeng/tabview';
import { BtnCellRenderer } from './consultant/list/btn-cell-renderer.component';
import { ConsultantMasterModule } from './consultant/consultant-master.module';
import { ProjectsListComponent } from './projects/list/list.component';
import { UtilizationComponent } from './reports/utilization/utilization.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { BtnfinanceCellRenderer } from './payments/btn-cell-renderer.component';

import {ToastModule} from 'primeng/toast';
import {TableModule} from 'primeng/table';
import {BadgeModule} from 'primeng/badge';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import { TagModule } from "primeng/tag";
import { AutoimportpayementComponent } from './payments/autoimportpayement/autoimportpayement.component';
import { InvoiceModule } from './invoice/invoice.modules';
import { AuthGuardGuard } from './auth/auth-guard.guard';


FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,

  interactionPlugin
]);
@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    HomeComponent,
    PaymentsComponent,
    BillingComponent,
    BtnCellRenderer,
    DashboardComponent,
    AddBillingComponent,
    ProjectsListComponent,
    UtilizationComponent,
    AutoimportpayementComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ComponentModule,

    FormsModule,
    BrowserAnimationsModule,
    DialogModule,
    ButtonModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),

    AgGridModule.withComponents([BtnCellRenderer,BtnfinanceCellRenderer]),
    DropdownModule,
    ReactiveFormsModule,
    ConsultantMasterModule,
    FullCalendarModule ,
    CalendarModule,
    TabViewModule,

    ToastModule,
    TableModule,
    BadgeModule,
    MessagesModule,
    MessageModule,
    TagModule,
    InvoiceModule,
    SweetAlert2Module.forRoot()

  ],
  providers: [AuthGuardGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import {
  ColDef,
  GridReadyEvent,
  GridApi,
  ColGroupDef,
  IDatasource,
  IGetRowsParams,
  ValueParserParams
} from 'ag-grid-community';

import { ConsultantService } from 'src/app/services/consultant.service';
import { BtnCellRenderer } from './btn-cell-renderer.component';

@Component({
  selector: 'app-consultant-master-dashboard',
  templateUrl: './consultant-master-dashboard.component.html',
  styleUrls: ['./consultant-master-dashboard.component.scss']
})
export class ConsultantMasterDashboardComponent implements OnInit {

  consultantname!: any;
  empname !: string;
  billing_period!:any;
  status: any = "";
  active: any = "1";

  private gridApi!: GridApi;
  @ViewChild('agGrid') agGrid!: AgGridAngular;
  columnDefs: ColDef[] = [
    {
      field: 'Edit',
      cellRenderer: 'btnCellRenderer',
      cellRendererParams: {
        clicked: (data:any)=>{
          console.warn(data);
          this.onEditConsultant(data)
        },
      },
      filter: 'false',
      width:80,
      floatingFilter: false,
      pinned: 'left'
    },
    { field: 'name', checkboxSelection: true, pinned: 'left' },
    { headerName: 'Account', field: 'account' },
    { headerName: 'Project', field: 'project' },
    {
      headerName: 'Start Date', field: 'start_dt', type: 'dateColumn',
      floatingFilter: true,
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
      cellRenderer: (data: any) => {
        return data.value ? (new Date(data.value)).toLocaleDateString() : '';
      }
    },
    {
      headerName: 'End Date', field: 'end_dt', type: 'dateColumn',
      floatingFilter: true,
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
      cellRenderer: (data: any) => {
        return data.value ? (new Date(data.value)).toLocaleDateString() : '';
      }
    },
    { field: 'status', sortable: true },
    { headerName: 'BGV', field: 'bgv',
      valueParser: numberParser,
      sortable: true,
      cellClassRules: {
        'rag-green': 'x <= 0',
        'rag-amber': 'x >= 1'
    }, },
    { headerName: 'Active', field: 'active'},
  ];




  frameworkComponents = {
    btnCellRenderer: BtnCellRenderer,
  };
  public defaultColDef: ColDef = {
    // set the default column width
    width: 200,
    // make every column editable
    editable: true,
    // make every column use 'text' filter by default
    filter: 'agTextColumnFilter',
    // enable floating filters by default
    floatingFilter: true,
    // make columns resizable
    resizable: true,

    sortable: true,
  };
  rowModelType = 'infinite';
  defaultPageSize = 10;
  rowSelection = "multiple"
  public defaultColGroupDef: Partial<ColGroupDef> = {};
  public rowGroupPanelShow = 'always';
  public pivotPanelShow = 'always';
  rowData: any = [];
  gridColumnApi: any;
  constructor(private http: HttpClient, private router:Router,private consultantService:ConsultantService) {}

  ngOnInit(): void {


  }
  getSelectedRows(): void {
    const selectedNodes = this.agGrid.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
  }
  onBtnExport() {
    this.gridApi.exportDataAsCsv();
  }
  // onGridReady(params: GridReadyEvent) {
  //   this.gridApi = params.api;
  //   this.gridColumnApi = params.columnApi;
  //   this.gridApi.setDatasource(this.dataSource);
  // }
  onGridReady(params: GridReadyEvent) {
   this.consultantService.getConsultants().subscribe(result=>{
     console.log('s',result)
     this.rowData = result;
   })
  }


  onEditConsultant(data: any) {
    console.log('onEditConsultant', data);
    this.router.navigate(
      ['/home/consultant'],
      { queryParams: { id: data.id } }
    );
  }

  dataSource: IDatasource = {
    getRows: (params: IGetRowsParams) => {
      this.consultantService.getConsultantsData(this.gridApi.paginationGetPageSize(), this.gridApi.paginationGetCurrentPage()).subscribe((response:any) => {
        console.log(response);
        params.successCallback(
          response, response.length
        );
      })
    }
  }
  onPageSizeChanged(event: any) {
    this.gridApi.paginationSetPageSize(Number(event.target.value));
  }

  onSearch(){
    this.consultantService.getConsultantswithFilter(this.consultantname, this.billing_period, this.status, this.active).subscribe(result=>{
      console.log('result',result);
      this.rowData = result;
    })
  }

  onClear(){
    this.consultantname = undefined;
    this.billing_period= undefined;
    this.status = "";
    this.getconsultants();

  }
  getconsultants(){
    this.consultantService.getConsultants().subscribe(result=>{
      console.log('s',result)
      this.rowData = result;
    })
  }
}






// Added Fiter Paramas variable
export var filterParams = {
  comparator: function (filterLocalDateAtMidnight: Date, cellValue: string) {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split('/');
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return -1;
  },
  browserDatePicker: true,
  minValidYear: 2000,
  maxValidYear: 2021,
};

function numberParser(params: ValueParserParams) {
  console.log(params);

  const newValue = params.newValue;
  let valueAsNumber;
  if (newValue === null || newValue === undefined || newValue === '') {
    valueAsNumber = null;
  } else {
    valueAsNumber = parseFloat(params.newValue);
  }
  return valueAsNumber;
}


import { Component, OnDestroy } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";



@Component({
  selector: "btn-cell-renderer",
  template: `
    <button (click)="btnClickedHandler($any($event.target).value)" class="btn  btn-primary"><i class="bi bi-pencil"></i></button>
  `
})
export class BtnCellRenderer implements ICellRendererAngularComp, OnDestroy {
  refresh(params: ICellRendererParams): boolean {
      throw new Error("Method not implemented.");
  }
  private params: any;

  agInit(params: any): void {
    this.params = params;
  }

  btnClickedHandler(event:any) {
    this.params.clicked(this.params.data);
    console.log('event:',event,'this.params.value:',this.params.data)
  }

  ngOnDestroy() {
    // no need to remove the button click handler
    // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
  }
}

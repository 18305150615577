import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  providers: [MessageService]
})
export class SignInComponent implements OnInit {
  userName:string | undefined;
  password:string | undefined;
  constructor(private router:Router,private messageService: MessageService) { }

  ngOnInit(): void {
  }
  onLogin(){
    if(this.userName ==="mindtechinc" && this.password === "MindTech@9876"){
      sessionStorage.setItem('auth','true')
      this.router.navigate(['/home']);
    }else{
      this.messageService.add({severity:'error', summary:'Login unsucessfull', detail:'Invalid username and password'});
    }

  }
  public isLogin():boolean
  {
    return false;
    // if(this.userName!== undefined || this.password !== undefined){
    //   return true;
    // }else{
    //   return false;
    // }
  }
}

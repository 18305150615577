<div class="container-fluid p-0">
  <div class="mb-3">
    <!-- <h1 class="h3 d-inline align-middle">Billing</h1> -->
    <h1 class="h3 d-inline align-middle"><strong>Billing Details</strong> </h1>
    <!-- <a class="badge bg-dark text-white ms-2" [routerLink]="['/home']">Import Payment Details</a> -->
  </div>
  <div class="row">

    <div class="alert alert-primary alert-dismissible" role="alert">
      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      <div class="alert-icon">
        <i class="far fa-fw fa-bell"></i>
      </div>
      <div class="alert-message">
        <strong>Hello there!</strong> A simple primary alert—check it out!
      </div>
    </div>

  </div>

  <div class="row">
    <div class="col-md-4 col-xl-3">
      <div class="mb-3">
        <p-dropdown class="form-label"  [options]="consultants" [(ngModel)]="selectedConsultant" optionLabel="name" [filter]="true" filterBy="name" [showClear]="true" placeholder="Consultant">
          <ng-template pTemplate="selectedItem">
              <div class="country-item country-item-value"  *ngIf="selectedConsultant">
                  <div>{{selectedConsultant.name}}</div>
              </div>
          </ng-template>
          <ng-template let-country pTemplate="item">
              <div class="country-item">
                  <div>{{country.name}}</div>
              </div>
          </ng-template>
      </p-dropdown>

      </div>
    </div>
    <div class="col-2 text-end">
      <!-- <br/> -->
  <button type="button" class="btn btn-primary" (click)="showResponsiveDialog()" >
   Add Bill
  </button>
</div>
  </div>

  <div class="row">
    <div class="col-12 col-lg-8 col-xxl-7 d-flex">
      <div class="card flex-fill">
        <div class="card-header">

          <h5 class="card-title mb-0">Weekly Report</h5>
        </div>
        <table class="table table-hover my-0">
          <thead>
            <tr>
              <th>Name</th>
              <th>Project Name</th>
              <th class="d-none d-xl-table-cell">Start Date</th>
              <th class="d-none d-xl-table-cell">End Date</th>
              <th>Attendance</th>
              <th>Status</th>
              <th class="d-none d-md-table-cell">Contact Person</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Week-1</td>
              <td class="d-none d-xl-table-cell">MindTech Co Op.</td>
              <td class="d-none d-xl-table-cell">01/01/2022</td>
              <td class="d-none d-xl-table-cell">05/01/2022</td>
              <td class="d-none d-xl-table-cell">40</td>
              <td><span class="badge bg-success">Payment Done</span></td>
              <td class="d-none d-md-table-cell">Vanessa Tucker</td>
            </tr>
            <tr>
              <td>Week-2</td>
              <td class="d-none d-xl-table-cell">MindTech Co Op.</td>
              <td class="d-none d-xl-table-cell">08/01/2022</td>
              <td class="d-none d-xl-table-cell">13/01/2022</td>
              <td class="d-none d-xl-table-cell">40</td>
              <td><span class="badge bg-danger">Payment Pending</span></td>
              <td class="d-none d-md-table-cell">Vanessa Tucker</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
</div>



<p-dialog header="Add Billing" [(visible)]="displayResponsive" [maximizable]="true" [breakpoints]="{'960px': '75vw'}" [style]="{width: '90vw'}"
    [draggable]="false" [resizable]="false">
          <app-add-billing></app-add-billing>
        <ng-template pTemplate="footer">
        <button (click)="displayResponsive=false" label="Yes" class="btn btn-primary">Save</button>
        <button (click)="displayResponsive=false" label="No" class="btn btn-secondary">cancel</button>
        </ng-template>
</p-dialog>

<div class="container-fluid p-0">
    <div class="mb-3">
      <!-- <h1 class="h3 d-inline align-middle">Billing</h1> -->
      <h1 class="h3 d-inline align-middle"><strong>Consultant</strong> Details</h1>
      <!-- <a class="badge bg-dark text-white ms-2" [routerLink]="['/home']">Import Payment Details</a> -->
    </div>

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"> <a [routerLink]="['/home']">Home</a> </li>
      <li class="breadcrumb-item"> <a [routerLink]="['/home/consultants']">Consultants</a> </li>
      <li class="breadcrumb-item active">Consultant</li>
    </ol>
  </nav>

  <div class="row">
    <div class="col-md-12 col-xl-12">

  <div class="alert alert-danger" role="alert" *ngIf="consultantModel.status == 'Terminated'">
    <h4 class="alert-heading">Terminated</h4>
    <p>Consultat is terminated, and no longer available for services.</p>
    <hr>
    <p class="mb-0">Do you want to resume user omboaridng back to company?</p>
    <button type="button" (click)="onResumed()"  class="btn btn-lg-0 btn-success">Resume</button> &nbsp;
  </div>

  </div>
  </div>


      <div class="row">
          <div class="col-12">
              <div class="card">
                  <div class="card-header">
                    <!-- {header}}  -->
                      <h5 class="card-title mb-0">Basic Details</h5>
                  </div>
                  <div class="card-body">
                      <form name="form" (ngSubmit)="onSubmit()">
                          <div class="container-fluid-0">
                      <div class="row">

                              <div class="col-4 mb-3">
                                  <label class="form-label">Name</label>
                                  <input class="form-control form-control-lg" type="text" [(ngModel)]="consultantModel.name" name="name" placeholder="name" />
                              </div>

                              <div class="col-4 mb-3">
                                <label class="form-label">Phone</label>
                                <input class="form-control form-control-lg" type="text" name="phone" [(ngModel)]="consultantModel.phone" placeholder="phone" />
                            </div>

                            <div class="col-4 mb-3">
                              <label class="form-label">Email</label>
                              <input class="form-control form-control-lg" type="email" name="email" [(ngModel)]="consultantModel.email" placeholder="email" />
                          </div>


                              <div class="d-flex flex-row-reverse mt-5" *ngIf="consultantModel.status != 'Terminated'">
                                <button type="button" (click)="onTerminated()"  class="btn btn-lg-0 btn-danger">Terminate</button> &nbsp;
                                <button type="submit"  class="btn btn-lg-0 btn-primary">Save</button> &nbsp;
                                <a [routerLink]="['/home/consultants']" class="btn btn-lg-0 btn-secondary">Cancel</a>
                              </div>

                      </div>
                  </div>
                  </form>
                  </div>
              </div>
          </div>
      </div>



  </div>




  <div class="container-fluid p-0">
    <!-- <div class="mb-3">
      <h1 class="h3 d-inline align-middle"><strong>Project</strong> Details</h1>
    </div> -->

    <div class="row">
      <div class="col-12">
          <div class="card">
              <div class="card-header">
                  <h5 class="card-title mb-0">Project Details</h5>
              </div>
              <div class="card-body">
                  <form name="form" (ngSubmit)="onSubmit()">
                      <div class="container-fluid-0">
                  <div class="row">


                          <!-- <hr/> -->

                          <div class="col-4 mb-3">
                            <label class="form-label">Account</label>
                            <input class="form-control form-control-lg" type="text" name="account" [(ngModel)]="consultantModel.account"placeholder="" />
                        </div>

                        <div class="col-4 mb-3">
                          <label class="form-label">Project</label>
                          <input class="form-control form-control-lg" type="text" name="project" [(ngModel)]="consultantModel.project" placeholder="" />
                      </div>

                      <div class="col-4 mb-3">
                        <label class="form-label">Status </label>
                        <select class="form-select" [(ngModel)]="consultantModel.status" name="status" disabled>
                          <option value="Onboarded">Onboarded</option>
                          <option value="Available">Available</option>
                          <option value="Terminated" >Terminated</option>
                        </select>
                        <!-- {{consultantModel.status}} -->
                    </div>


                    <div class="col-4 mb-3">
                      <label class="form-label">Rate</label>
                      <input class="form-control form-control-lg" type="text" name="rate" [(ngModel)]="consultantModel.rate" placeholder="" />
                  </div>

                  <div class="col-4 mb-3">
                    <label class="form-label">Payment Terms</label>
                        <select class="form-select" [(ngModel)]="consultantModel.payment_terms" name="payment_terms">
                          <option value="">Select Payment Terms</option>
                          <option value="30">30 Days</option>
                          <option value="45">45 Days</option>
                          <option value="60">60 Days</option>
                          <option value="90" >90 Days</option>
                        </select>

                  </div>

                  <div class="col-4 mb-3">

                  </div>


                      <div class="col-4">
                        <label class="form-label">Start Date</label>
                        <p-calendar [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" [(ngModel)]="consultantModel.start_dt"   name="start_dt"></p-calendar>
                    </div>

                    <div class="col-4">
                      <label class="form-label">End Date</label>
                      <p-calendar [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" [(ngModel)]="consultantModel.end_dt" name="end_dt"  ></p-calendar>
                  </div>

                  <div class="col-4 mb-3">
                    <label class="form-label">Billing Period</label>
                    <select class="form-select" [(ngModel)]="consultantModel.billing_period" name="billin_period">
                      <option selected value="Weekly">Weekly</option>
                      <option value="Fortnightly">Fortnightly</option>
                      <option value="Monthly" selected>Monthly</option>
                    </select>
                </div>



                          <div class="d-flex flex-row-reverse mt-5" *ngIf="consultantModel.status != 'Terminated'">
                            <button type="submit" (click)="onMapping('Available')"  class="btn btn-lg-0 btn-warning">Release</button> &nbsp;
                            <button type="submit" (click)="onMapping('Onboarded')" class="btn btn-lg-0 btn-primary">Save</button> &nbsp;
                            <a [routerLink]="['/home/consultants']" class="btn btn-lg-0 btn-secondary">Cancel</a>
                          </div>

                  </div>
              </div>
              </form>
              </div>
          </div>
      </div>
  </div>
  </div>

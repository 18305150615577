import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { EChartsOption } from 'echarts';
import { DashboardService } from '../services/dashboard.service';
import * as echarts from 'echarts';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  @ViewChild("container", { read: ElementRef }) container!: ElementRef;

  projectCount: number = 0;
  billingSum: number = 0;
  paymentSum: any = 0;
  x_axis: any = [];
  y_axis:any =[];
  series_bill: any = [];
  series_payment: any = [];
  pie_Chart_Value: any =[];



  option!:EChartsOption;

  maxB: any = 0;
  maxP: any = 0;




   monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
// PIE Chart
  optionPIE!:EChartsOption ;
  barGradientoption !:EChartsOption ;
  drilldownData: any=[];


  constructor(private dashboardService:DashboardService) { }

  ngOnInit(): void {

    //this.barchartGraident();


    this.dashboardService.getCounter().subscribe((result:any)=>{
      console.log(result);
      this.projectCount = result.length;
    });

    this.dashboardService.getfinance().subscribe((result:any)=>{
      console.log('result',result);
      if(result[0]) {
        this.billingSum = result[0].sum_of_bill;
        this.paymentSum = result[0].sum_of_payment;
      }
    });

    this.dashboardService.projectRevenue()
    .subscribe((result:any)=>{
      console.log('result3',result);
      result.forEach((element:any) => {
          console.log(element);
          this.pie_Chart_Value.push( { value: element["sum(bill)"], name: element.pid });
        });
       this.optionPIE = {
          title: {

            left: 'center'
          },
          tooltip: {
            trigger: 'item'
          },
          legend: {
            orient: 'vertical',
            left: 'left'
          },
          series: [
            {
              name: 'Project Distribution',
              type: 'pie',
              radius: '50%',
              data:this.pie_Chart_Value,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        };
    });


    this.dashboardService.postdynamicQUery().subscribe((result:any)=>{
      console.log('resultq',result);

      this.x_axis = [];
      this.y_axis = [];

      result.forEach((element:any) => {
          console.log(element);

            this.x_axis.push(this.monthNames[element["month(_d)"]-1]);

        //     this.y_axis.push({
        //       type: 'value',
        //       name: 'Payments',
        //       min: 0,
        //       max: element["sum(payment)"],
        //       interval: 30,
        //       axisLabel: {
        //         formatter: '{value} USD'
        //       }
        //     },
        //     {
        // type: 'value',
        // name: 'revenue',
        // min: 0,
        // max:element["sum(bill)"],
        // interval: 5,
        // axisLabel: {
        //   formatter: '{value}  USD'
        // }});




            this.series_bill.push(element["sum(bill)"]);
            if(this.maxB < element["sum(bill)"]) {
              this.maxB = element["sum(bill)"];
            }

            this.series_payment.push(element["sum(payment)"]);
            if(this.maxP < element["sum(payment)"]) {
              this.maxP = element["sum(payment)"];
            }
        });

        this.option  = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          toolbox: {
            feature: {
              dataView: { show: false, readOnly: false },
              magicType: { show: false, type: ['line', 'bar'] },
              restore: { show: false },
              saveAsImage: { show: false }
            }
          },
          legend: {
            data: ['Billing', 'Payment', 'Target']
          },
          xAxis: [
            {
              type: 'category',
              data: this.x_axis,
              axisPointer: {
                type: 'shadow'
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              name: 'USD',
              min: 0,
              max: this.billingSum,
              interval: this.billingSum/10,
              axisLabel: {
                formatter: '{value} '
              }
            },
            // {
            //   type: 'value',
            //   name: 'Payment',
            //   min: 0,
            //   max: this.maxP,
            //   // interval: 500,
            //   // axisLabel: {
            //   //   formatter: '{value} '
            //   // }
            // }
          ],
          series: [
            {
              name: 'Billing',
              type: 'bar',
              tooltip: {
                valueFormatter:((value: any) => value + ' USD')
              },
              data: this.series_bill
            },
            {
              name: 'Payment',
              type: 'bar',
              tooltip: {
                valueFormatter: ((value: any) => value + ' USD')
              },
              data: this.series_payment
            },
            // {
            //   name: 'Target',
            //   type: 'line',
            //   yAxisIndex: 1,
            //   tooltip: {
            //     valueFormatter: ((value: any) => value + ' USD')
            //   },
            //   data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2]
            // }
          ]
        };

        console.log(' this.option.xAxis ', this.option.xAxis );
    });




  }

barchartGraident(){

  this.barGradientoption = {
    xAxis: {
      data: ['Animals', 'Fruits', 'Cars']
    },
    yAxis: {},
    dataGroupId: '',
    animationDurationUpdate: 500,
    series: {
      type: 'bar',
      id: 'sales',
      data: [
        {
          value: 5,
          groupId: 'animals'
        },
        {
          value: 2,
          groupId: 'fruits'
        },
        {
          value: 4,
          groupId: 'cars'
        }
      ] as DataItem[],
      universalTransition: {
        enabled: true,
        divideShape: 'clone'
      }
    }
  };

   this.drilldownData = [
    {
      dataGroupId: 'animals',
      data: [
        ['Cats', 4],
        ['Dogs', 2],
        ['Cows', 1],
        ['Sheep', 2],
        ['Pigs', 1]
      ]
    },
    {
      dataGroupId: 'fruits',
      data: [
        ['Apples', 4],
        ['Oranges', 2]
      ]
    },
    {
      dataGroupId: 'cars',
      data: [
        ['Toyota', 4],
        ['Opel', 2],
        ['Volkswagen', 2]
      ]
    }
  ];
return this.barGradientoption

}
barclick(event:any){



if (event.data) {

  var subData = this.drilldownData.find(function (data: { dataGroupId: string; }) {
    return data.dataGroupId === (event.data as DataItem).groupId;
  });
  let pichartData = subData.data.map(function (item: any[]) {

    return { value: item[1], name: item[0] } ;
  });
  console.log('this',pichartData);
  // if (!subData) {
  //   return;
  // }
  // console.log('before',this.barGradientoption.xAxis);
  // this.barGradientoption.xAxis = {
  //   data: subData.data.map(function (item: any[]) {

  //     return item[0];
  //   })
  // }
  // console.log('after',this.barGradientoption.xAxis);
  // console.log('before',this.barGradientoption.series);
  // this.barGradientoption.series =  {
  //   type: 'bar',
  //   id: 'sales',
  //   dataGroupId: subData.dataGroupId,
  //   data: subData.data.map(function (item: any[]) {
  //     return {value:item[1]};
  //   }),
  //   universalTransition: {
  //     enabled: true,
  //     divideShape: 'split'
  //   }
  // }
  // console.log('after',this.barGradientoption.series);
  // this.barGradientoption.graphic = [
  //   {
  //     type: 'text',
  //     left: 50,
  //     top: 20,
  //     style: {
  //       text: 'Back',
  //       fontSize: 18
  //     },
  //     onclick:this.barGradientoption = this.barchartGraident() as any,
  //   }
  // ]
  this.barGradientoption ={
    title: {

      left: 'center'
    },
    tooltip: {
      trigger: 'item'
    },
    legend: {
      orient: 'vertical',
      left: 'right'
    },
    series: [
      {
        name: 'Project Distribution',
        type: 'pie',
        radius: '50%',
        data:pichartData,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  };
  this.barGradientoption.graphic=[{
    type:'text',
    left:50,
    top:1,
    style:{
      text:'back'
    },
    $action:'replace',
    onclick:(e)=>{
      this.barchartGraident()
    }
  }]

}

}
setBarChartOnClick(){
   this.barGradientoption = this.barGradientoption

}
}
export interface DataItem {
  value: number;
  groupId: string;
}

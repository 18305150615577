<div class="container-fluid p-0">
<div class="mb-3">
  <!-- <h1 class="h3 d-inline align-middle">Consultant(s)</h1> -->
  <h1 class="h3 mb-3"><strong>Analytics</strong> Dashboard</h1>
</div>

<div class="row">
  <div class="col-12 col-md-6 col-xl d-flex">
    <div class="card flex-fill">
      <div class="card-body py-4">
        <div class="stat text-primary float-end">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users align-middle"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
        </div>
        <h4 class="mb-2">Projects</h4>
        <div class="mb-1">
          <strong>
           {{projectCount}}
          </strong>
          <!-- $203.77 -->
        </div>
        <div class="text-success">
          <!-- Projected Revenue: 4,27,563 USD -->
          <!-- Growth Rate (from 2021): <span class="">+1.19%</span> -->
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-md-6 col-xl d-flex">
    <div class="card flex-fill">
      <div class="card-body py-4">
        <!-- <div class="float-end text-danger">
          -6.71%
        </div> -->
        <div class="stat text-primary float-end">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-briefcase align-middle"><rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect><path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path></svg>
        </div>
        <h4 class="mb-2">Billing</h4>
        <div class="mb-1">
          <strong>
            $ {{billingSum}}
          </strong>
          <!-- $6.86 -->
        </div>
        <div class="text-warning">
          <!-- Projected Revenue (2022): 4,27,563 USD -->
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6 col-xl d-flex">
    <div class="card flex-fill">
      <div class="card-body py-4">
        <div class="stat text-primary float-end">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign align-middle"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
        </div>
        <!-- <div class="float-end text-success">
          1.88%
        </div> -->
        <h4 class="mb-2">Payments</h4>
        <div class="mb-1">
          <strong>
            $ {{paymentSum}}
          </strong>
          <!-- $42.85 -->
        </div>
        <div class="float-start text-danger">
          <!-- Dues (as of Feb, 2022): $666.00 -->
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="col-12 col-md-6 col-xl d-none d-xxl-flex">
    <div class="card flex-fill">
      <div class="card-body py-4">
        <div class="float-end text-danger">
          -1.26%
        </div>
        <h4 class="mb-2">XMR/BTC</h4>
        <div class="mb-1">
          <strong>
            0.00700518
          </strong>
          $61.80
        </div>
        <div>
          Volume: 28,567 BTC
        </div>
      </div>
    </div>
  </div> -->
</div>

<!-- <div class="row">
  <div class="col-xl-6 col-xxl-5 d-flex">
    <div class="w-100">
      <div class="row">
        <div class="col-sm-6">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col mt-0">
                  <h5 class="card-title">Consultants</h5>
                </div>

                <div class="col-auto">
                  <div class="stat text-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users align-middle"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                  </div>
                </div>
              </div>
              <h1 class="mt-1 mb-3">302</h1>
              <div class="mb-0">
                <span class="text-success"> <i class="mdi mdi-arrow-bottom-right"></i> 0% </span>
                <span class="text-muted">Since last month</span>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col mt-0">
                  <h5 class="card-title">Payments</h5>
                </div>

                <div class="col-auto">
                  <div class="stat text-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign align-middle"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                  </div>
                </div>
              </div>
              <h1 class="mt-1 mb-3">$32,000</h1>
              <div class="mb-0">
                <span class="text-success"> <i class="mdi mdi-arrow-bottom-right"></i> 5.25% </span>
                <span class="text-muted">Since last month</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col mt-0">
                  <h5 class="card-title">Clients</h5>
                </div>

                <div class="col-auto">
                  <div class="stat text-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-briefcase align-middle"><rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect><path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path></svg>
                  </div>
                </div>
              </div>
              <h1 class="mt-1 mb-3">21</h1>
              <div class="mb-0">
                <span class="text-success"> <i class="mdi mdi-arrow-bottom-right"></i> 6.65% </span>
                <span class="text-muted">Since last month</span>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col mt-0">
                  <h5 class="card-title">Dues</h5>
                </div>

                <div class="col-auto">
                  <div class="stat text-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign align-middle"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                  </div>
                </div>
              </div>
              <h1 class="mt-1 mb-3">$1,100</h1>
              <div class="mb-0">
                <span class="text-danger"> <i class="mdi mdi-arrow-bottom-right"></i> -2.25% </span>
                <span class="text-muted">Since last week</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-6 col-xxl-7">
    <div class="card flex-fill w-100">
      <div class="card-header">

        <h5 class="card-title mb-0">Monthly Transactions Trend</h5>
      </div>

    </div>
  </div>
</div> -->


<div class="row">
  <div class="col-12 col-lg-8 col-xxl-7 d-flex">
    <div class="card flex-fill">
      <div class="card-header">

        <h5 class="card-title mb-0">Accounts</h5>
      </div>

      <div class="card-body">
        <div echarts [options]="option" class="demo-chart"></div>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-4 col-xxl-5 d-flex">
    <div class="card flex-fill w-100">
      <div class="card-header">
        <h5 class="card-title mb-0">Project Revenue Distribution</h5>
      </div>
      <div class="card-body ">
        <div echarts [options]="optionPIE" class="demo-chart"></div>
      </div>
    </div>
  </div>

  <!-- <div class="col-12 col-lg-4 col-xxl-5 d-flex">
    <div class="card flex-fill w-100">
      <div class="card-header">
        <h5 class="card-title mb-0">Project Wise Report</h5>
      </div>
      <div class="card-body ">
        <div echarts [options]="barGradientoption" #container (chartClick)="barclick($event)" class="demo-chart"></div>
      </div>
    </div>
  </div> -->
</div>




</div>
